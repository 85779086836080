/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, dynamicCost, getSuccessMessageByModule } from '~/Utils/index';

import api from '~/services/api';

const initialValues = {
  reference_year: '',
  description: '',
  cost_center_id: '',
  bank_account_id: '',
  graduation_student_value: '',
  postdoctoral_student_value: '',
  professional_value: '',
};

export default function AnnuityForm({ visible, onClose, annuityID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchCostCenters = async () => {
    try {
      const { data } = await api.get('/costCenters');
      setCostCenterOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const { data } = await api.get('/bankAccounts');
      setbankAccountOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    delete values.isEdit;
    try {
      if (values.id) {
        await api.put(`/annuities/${values.id}`, values);
        message.success(
          getSuccessMessageByModule(t('screens:annuities.titleSingular'), t('messages:successUpdatedFem'))
        );
      } else {
        await api.post('/annuities', values);
        message.success(
          getSuccessMessageByModule(t('screens:annuities.titleSingular'), t('messages:successCreatedFem'))
        );
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecord = async () => {
    if (!annuityID) {
      setRecordData(initialValues);
    } else {
      const response = await api.get(`/annuities/${annuityID}`);
      const data = JSON.parse(response.data);
      setRecordData(data);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCostCenters(), fetchBankAccounts()]);
      await fetchRecord();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const AnnuitySchema = Yup.object().shape({
    description: Yup.string().required(),
    reference_year: Yup.date().required(),
    cost_center_id: Yup.number().integer().required(),
    bank_account_id: Yup.number().integer().required(),
    graduation_student_value: Yup.number().min(1).required(),
    postdoctoral_student_value: Yup.number().min(1).required(),
    professional_value: Yup.number().min(1).required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={AnnuitySchema}
    >
      {({ errors, isSubmitting, submitForm, values, resetForm, setValues }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.description}` : t('screens:annuities.title')}
          onCancel={onClose}
          style={{ maxWidth: '950px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@annuity/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row gutter={16}>
                <Col span={12}>
                  <FormControl
                    error={errors.description}
                    field="description"
                    label={t('screens:annuities.data.description')}
                    required
                  >
                    <Input name="description" disabled={permissions && !permissions.includes('@annuity/edit')} />
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    error={errors.reference_year}
                    field="reference_year"
                    required
                    label={t('screens:annuities.data.reference_year')}
                  >
                    <DatePicker
                      format="Y"
                      name="reference_year"
                      placeholder={t('messages:select')}
                      locale={locale}
                      picker="year"
                      disabled={recordData.id || (permissions && !permissions.includes('@annuity/edit'))}
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormControl
                    error={errors.cost_center_id}
                    field="cost_center_id"
                    label={t('fields:cost_center_id')}
                    required
                  >
                    <Select
                      name="cost_center_id"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      allowClear
                    >
                      {costCenterOptions &&
                        costCenterOptions.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col span={12}>
                  <FormControl
                    error={errors.bank_account_id}
                    field="bank_account_id"
                    label={t('fields:bank_account_id')}
                    required
                  >
                    <Select
                      name="bank_account_id"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      allowClear
                    >
                      {bankAccountOptions &&
                        bankAccountOptions.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.description} - {item.bank}
                          </Select.Option>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <FormControl
                    error={errors.graduation_student_value}
                    field="graduation_student_value"
                    label={t('fields:graduation_student_value')}
                    required
                  >
                    <InputCurrency
                      name="graduation_student_value"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      currency="R$"
                      maxLength="9"
                      onChange={event =>
                        setValues({ ...values, graduation_student_value: dynamicCost(event.target.value) })
                      }
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    error={errors.mastersdegree_student_value}
                    field="mastersdegree_student_value"
                    label={t('fields:mastersdegree_student_value')}
                    required
                  >
                    <InputCurrency
                      name="mastersdegree_student_value"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      currency="R$"
                      maxLength="9"
                      onChange={event =>
                        setValues({ ...values, mastersdegree_student_value: dynamicCost(event.target.value) })
                      }
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    error={errors.doctorate_student_value}
                    field="doctorate_student_value"
                    label={t('fields:doctorate_student_value')}
                    required
                  >
                    <InputCurrency
                      name="doctorate_student_value"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      currency="R$"
                      maxLength="9"
                      onChange={event =>
                        setValues({ ...values, doctorate_student_value: dynamicCost(event.target.value) })
                      }
                    />
                  </FormControl>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <FormControl
                    error={errors.postdoctoral_student_value}
                    field="postdoctoral_student_value"
                    label={t('fields:postdoctoral_student_value')}
                    required
                  >
                    <InputCurrency
                      name="postdoctoral_student_value"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      currency="R$"
                      maxLength="9"
                      onChange={event =>
                        setValues({ ...values, postdoctoral_student_value: dynamicCost(event.target.value) })
                      }
                    />
                  </FormControl>
                </Col>
                <Col span={8}>
                  <FormControl
                    error={errors.professional_value}
                    field="professional_value"
                    label={t('fields:professional_value')}
                    required
                  >
                    <InputCurrency
                      name="professional_value"
                      disabled={permissions && !permissions.includes('@annuity/edit')}
                      currency="R$"
                      maxLength="9"
                      onChange={event => setValues({ ...values, professional_value: dynamicCost(event.target.value) })}
                    />
                  </FormControl>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
